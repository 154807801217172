import { toast, ToastContent, ToastOptions } from 'react-toastify'

const defaultOptions: ToastOptions = {
  autoClose: 5000,
  progress: undefined,
  hideProgressBar: false,
  pauseOnHover: true,
}

export const helperToast = {
  success: (
    content: ToastContent,
    opts?: ToastOptions,
    dismissOther?: boolean,
  ) => {
    if (dismissOther) {
      toast.dismiss()
    }
    toast.success(content, {
      ...defaultOptions,
      ...opts,
    })
  },
  error: (
    content: ToastContent,
    opts?: ToastOptions,
    dismissOther?: boolean,
  ) => {
    if (dismissOther) {
      toast.dismiss()
    }
    toast.error(content, {
      ...defaultOptions,
      ...opts,
    })
  },
  info: (
    content: ToastContent,
    opts?: ToastOptions,
    dismissOther?: boolean,
  ) => {
    if (dismissOther) {
      toast.dismiss()
    }
    toast(content, {
      ...defaultOptions,
      ...opts,
    })
  },
}
