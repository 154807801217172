import { PRODUCTION_PREVIEW_KEY } from './localStorage'

export const UI_VERSION = '1.4'

export const IS_TOUCH =
  typeof window !== 'undefined' && typeof window.ontouchstart !== 'undefined'
    ? window.ontouchstart
    : false

export function isDevelopment() {
  if (typeof window === 'undefined') {
    return false
  }
  const isProductionPreview = Boolean(
    localStorage.getItem(PRODUCTION_PREVIEW_KEY),
  )
  return (
    !window.location.host?.includes('gmx.io') &&
    !window.location.host?.includes('ipfs.io') &&
    !isProductionPreview
  )
}

export function isLocal() {
  if (typeof window === 'undefined') {
    return false
  }
  return window.location.host?.includes('localhost')
}
