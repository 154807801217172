import { DEFAULT_CHAIN_ID } from 'config/chains'
import { useEthersSigner } from 'hooks/contracts/useEthersSigner'
import { useAccount } from 'wagmi'

export default function useWallet() {
  const { address, isConnected, connector, chain } = useAccount()
  const signer = useEthersSigner()
  return {
    account: address as string,
    active: isConnected,
    connector,
    chainId: chain?.id || DEFAULT_CHAIN_ID,
    signer: signer ?? undefined,
  }
}
